.loader_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SkeltonLoading {
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 1rem;

  &__img {
    flex: 1;
    aspect-ratio: 1/1;
  }
  &__content {
    flex: 5;
  }
}
